import RouterPath from "./RouterPath";

import "./styles/global.scss";
import "./styles/globals.css";
import "./fonts/Garamond.woff2";
import "./fonts/Radar.ttf";
import "./fonts/PTSerif-Regular.woff2";

function App() {
	return (
		<RouterPath />
	);
}

export default App;