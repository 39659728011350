import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

import './index.css';
import AudioRecorder from 'audio-recorder-polyfill'
window.MediaRecorder = AudioRecorder
const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<App />
);